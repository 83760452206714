<template>
  <li>
    <div class="list_item">
      <a :href="businessLink" @click.prevent="handleClick">
        <span class="logo">
          <img :src="thumbnail" alt="" width="34">
        </span>
        <span class="title font_h6">{{ title }}</span>
      </a>
      <div>
        <slot name="icon" />
      </div>
    </div>
    <p class="content font_p1">
      {{ content }}
    </p>
  </li>
</template>

<script setup lang="ts">
import PATH from '~/modules/content/config/content-path'

const { id, title, content, thumbnail } = withDefaults(
  defineProps<{
    id: string | number, title: string, content: string, thumbnail: string
  }>(),
  {
    id: '', title: '', content: '', thumbnail: ''
  }
)

const emit = defineEmits<{(e: 'click', item: any): void}>()

const businessLink = `${PATH.BUSINESS_DETAIL}/${id}`

const handleClick = () => {
  emit('click', {
    id, title, content, thumbnail
  })
}

</script>

<style scoped>
li { padding:16px; background:var(--color-background-sub1); border-radius:8px; }
.list_item { position: relative; display: flex; justify-content: space-between; align-items: center; }
.list_item a { display: flex; align-items: center; gap: 8px; width: fit-content; width: 100%; }

.logo { position: relative; display: inline-block; width:36px; height:36px; background:#fff; border:1px solid var(--color-line);  border-radius:100%; box-sizing:border-box; overflow:hidden; }
.logo img { position: absolute; top: -100%; right: -100%; bottom: -100%; left: -100%; width: 100%; height: 100%; margin: auto; }

.title { color: var(--color-text-title); max-width: calc(100% - 55px); display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden; white-space: normal; word-break: break-all; }
.content { color:var(--color-text-filled); margin-top:8px; }

</style>
