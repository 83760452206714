<template>
  <div class="link_keyword">
    <ul class="company_list">
      <li v-for="(item, index) in list" :key="index">
        <span>
          <img :src="thumbnailImageWithOptions(item, {w: 57})" alt="">
        </span>
      </li>
      <li class="btn_more">
        <span>
          <i class="icon_font_more_02" />
        </span>
      </li>
    </ul>
    <a :href="`${PATH.POST_RECOMMEND_LIST}/${recommendSeq}`" class="btn_ty_01" @click.prevent="handleClick">더 알아보기</a>
  </div>
</template>

<script setup lang="ts">
import PATH from '~/modules/content/config/content-path'
import { useImagesThumbnail } from '~/composables/images/thumbnail'
const { recommendSeq, list } = withDefaults(
  defineProps<{ recommendSeq:number, list: string[] }>(), { recommendSeq: 0, list: () => [] }
)

const emit = defineEmits<{(e: 'click'): void }>()
const { thumbnailImageWithOptions } = useImagesThumbnail()

const handleClick = () => {
  emit('click')
}

</script>

<style scoped>
.link_keyword{position:relative; display: flex; justify-content: space-between; align-items: center;}
.link_keyword .company_list{overflow:hidden; padding-left:10px;}
.link_keyword .company_list li{float:left; margin-left:-10px;}
.link_keyword .company_list li span{position:relative; display:block; width:42px; height:42px; border:2px solid var(--color-background-default); background:#fff; overflow:hidden; border-radius:100%; box-sizing:border-box;}
.link_keyword .company_list li img{position: absolute; top: -100%; right: -100%; bottom: -100%; left: -100%; width: 100%; height: 100%; margin: auto;}
.link_keyword .company_list .btn_more span{background:var(--color-line); display: flex; align-items: center; justify-content: center;}
.link_keyword .company_list .btn_more span{background:var(--color-line); display: flex; align-items: center; justify-content: center; width:42px; height:42px; position:relative; z-index:1; border:2px solid var(--color-background-default); border-radius:100%; box-sizing:border-box;}
.link_keyword .icon_font_more_02:before{font-size:24px;}
.link_keyword .btn_ty_01{width:155px;}

@media screen and (max-width: 1500px) {
  .link_keyword .btn_ty_01{ width:360px; }
}

@media screen and (max-width: 768px) {
  .link_keyword .btn_ty_01{ width:135px; }
}
</style>
