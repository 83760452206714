<template>
  <li>
    <div class="list_item" :class="{ read: readYn === 'Y' }">
      <div class="text_box">
        <div class="writer">
          <a :href="writerLink" @click.prevent="handleClickWriter">
            <span class="logo">
              <img :src="writerThumbnail" alt="" width="282">
            </span>
            <span class="name font_p3">{{ writerName }}</span>
            <span class="date font_p3">{{ createDate }}</span>
          </a>

          <div class="icon">
            <slot name="icon" />
          </div>
        </div>

        <a :href="postLink" class="content" @click.prevent="handleClickItem">
          <span class="title font_h6">{{ title }}</span>
          <span class="txt font_p2">{{ content }}</span>
        </a>

        <ul class="tag_list">
          <li v-for="tag in tags" :key="tag">
            <a :href="tagLink" @click.prevent="handleClickTag(tag)">#{{ tag }}</a>
          </li>
        </ul>
      </div>
    </div>
  </li>
</template>
<script setup lang="ts">
import { AFFECT_TYPE, SEARCH_AFFECT_TYPE, TPostSort } from '~/modules/content/types/type'
import PATH from '~/modules/content/config/content-path'

const { item, id, title, writerId, writerEnId, writerThumbnail, writerName, createDate, readYn } = withDefaults(
  defineProps<{
    item: any, id: string | number, title: string, content: string, tags: string[],
    writerId: string | number, writerEnId: string, writerThumbnail: string, writerName: string, createDate: string, readYn: string
  }>(),
  {
    item: {},
    id: '',
    title: '',
    content: '',
    tags: () => [],
    writerId: '',
    writerEnId: '',
    writerThumbnail: '',
    writerName: '',
    createDate: '',
    readYn: 'N'
  }
)

const emit = defineEmits<{(e: 'clickItem', item: any): void, (e: 'clickWriter', item: any): void, (e: 'clickTag', tag: string): void }>()

const postLinkQuery = {
  writer: item.companyName,
  affect_type: AFFECT_TYPE.HOME
}
const postLink = `${PATH.POST_DETAIL}/${id}?${new URLSearchParams(postLinkQuery).toString()}`
const writerLink = `${PATH.BUSINESS_DETAIL}/${writerEnId}`
const tagLinkQuery = {
  sort: TPostSort.POPULAR,
  search_affect_type: SEARCH_AFFECT_TYPE.Related,
}
const tagLink = `${PATH.SEARCH_CONTENTS}?${new URLSearchParams(tagLinkQuery).toString()}`

const handleClickItem = () => {
  emit('clickItem', {
    id, title, writerId, writerEnId, writerThumbnail, writerName, createDate
  })
}

const handleClickWriter = () => {
  emit('clickWriter', {
    id, title, writerId, writerEnId, writerThumbnail, writerName, createDate
  })
}

const handleClickTag = (tag: string) => {
  emit('clickTag', tag)
}

</script>

<style scoped>
.list_item { display: flex; justify-content: space-between; padding: 16px 0; border-bottom: 1px solid var(--color-line); min-height:108px; }
.list_item .text_box { position: relative; width: 100%; }
.list_item .writer { position:relative; display: flex; justify-content: space-between; align-items: center; padding-right:24px; box-sizing:border-box; }
.list_item .writer a { width: fit-content; width:100%; display: flex; align-items: center; gap: 5px; }
.list_item .writer .logo { position:relative; display:inline-block; width:24px; height:24px; flex: none; background:#fff; border:1px solid var(--color-line);  border-radius:100%; box-sizing:border-box; overflow:hidden; }
.list_item .writer .logo img { position: absolute; top: -100%; right: -100%; bottom: -100%; left: -100%; width: 100%; height: 100%; margin: auto; }
.list_item .writer .name { color: var(--color-text-title); letter-spacing: 0; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden; white-space: normal; word-break: break-all; }
.list_item .writer .date { display: flex; align-items: center; gap: 5px; letter-spacing: 0; flex: none; }
.list_item .writer .date:before { content: ''; display: inline-block; width: 2px; height: 2px; background: var(--color-text-sub); border-radius: 100%; }
.list_item .writer .icon { position: absolute; right: 0; top: 0; width: 24px; }
.list_item .content { display: block; margin-top: 8px; }
.list_item .content span { display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden; white-space: normal; word-break: break-all; }
.list_item .content .title { font-size: var(--font-size-5); color: var(--color-text-headline); -webkit-line-clamp: 2; line-height: 130%; }
.list_item .content:hover .title { text-decoration: underline }
.list_item .content .txt { margin-top:4px; }
.list_item .thumbnail { display: block; position: relative; width: 282px; height: 160px; overflow: hidden; border-radius: 8px; }
.list_item .thumbnail img { position: absolute; top: -100%; right: -100%; bottom: -100%; left: -100%; width: 100%; height: 100%; margin: auto; object-fit: cover;}
.list_item .tag_list { margin-top: 20px; display: none; height: 22px; overflow: hidden; }
.list_item .tag_list li { display: inline-block; margin: 0 8px 0 0; }
.list_item .tag_list li a { display: inline-block; padding: 0 8px; line-height: 1.375rem; font-size: var(--font-size-8); color: var(--color-text-sub); background: var(--color-background-sub1); border-radius: 4px; box-sizing: border-box; }
.list_item .tag_list li:last-child { margin: 0; }

/*.list_item.read .thumbnail:after { content:''; display:block; position:absolute; left:0; top:0; width:100%; height:100%; background:var(--color-background-default); opacity:0.6; }*/
.list_item.read .content .title { color:var(--color-text-sub); }

@media screen and (max-width: 768px) {
  .list_item .tag_list { margin-top: 24px; }
  .list_item .thumbnail { margin-top: 32px; width: 88px; height: 88px; }
  .list_item .tag_list { display: block; }
}

</style>
