import type { UseFetchOptions } from '#app'

import { TPostSort } from '../types/type'
import type { IPostInterestListRequest, IListRequest } from '../types/request'
import type { IPostListReponse, IPostReponse } from '../types/response'
import { useCustomFetch } from '~/modules/core/fetch/custom-fetch'

/**
 * 게시물 상세 조회
 * @param req
 * @returns
 */
export function useFetchPost (id: number, option?: UseFetchOptions<IPostReponse>) {
  return useCustomFetch('/board/v1/blog', {
    ...option,
    params: { postSeq: id }
  })
}

/**
 * 게시물 인기 목록 조회
 * @param req
 * @returns
 */
export function useFetchPostListByPopuplar (req: IListRequest, option?: UseFetchOptions<IPostListReponse>) {
  const { currentPage, cntPerPage } = defaultPaging(req)
  const sortType = req.sortType ?? TPostSort.POPULAR

  return useCustomFetch('/blog/v1/popular/list', {
    ...option,
    params: { currentPage, cntPerPage, sortType }
  })
}

/**
 * 게시물 최신 목록 조회
 * @param req
 * @returns
 */
export function useFetchPostListByLatest (req: IListRequest, option?: UseFetchOptions<IPostListReponse>) {
  const { currentPage, cntPerPage } = defaultPaging(req)
  const sortType = req.sortType ?? TPostSort.LATEST

  return useCustomFetch('/blog/v1/latest/list', {
    ...option,
    params: { currentPage, cntPerPage, sortType }
  })
}

/**
 * 게시물 관심 목록 조회
 * @param req
 * @returns
 */
export function useFetchPostListByInterest (req: IPostInterestListRequest, option?: UseFetchOptions<IPostListReponse>) {
  const { currentPage, cntPerPage } = defaultPaging(req)
  const sortType = req.sortType ?? TPostSort.POPULAR
  const interests = req.interests

  return useCustomFetch('/blog/v1/interest/list', {
    ...option,
    params: { currentPage, cntPerPage, sortType, interests }
  })
}

/**
 * 보도 자료 게시물 목록 조회 - 홈
 * @param req
 * @returns
 */
export function useFetchPostListByReportAtHome (option?: UseFetchOptions<IPostListReponse>) {
  return useCustomFetch('/blog/v1/home/report/list', {
    ...option
  })
}

/**
 * 오운드 게시물 목록 조회 - 홈
 * @param req
 * @returns
 */
export function useFetchPostListByOnlyOundAtHome (option?: UseFetchOptions<IPostListReponse>) {
  return useCustomFetch('/blog/v1/ound/list', {
    ...option
  })
}

/**
 * 페이징 변수 기본값 설정
 * @param req 페이징 변수
 * @returns
 */
function defaultPaging (req: IListRequest) {
  return {
    currentPage: req.currentPage ?? 1,
    cntPerPage: req.cntPerPage ?? 10
  }
}
