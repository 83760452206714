<template>
  <ul class="row_list">
    <slot />
  </ul>
</template>
<script setup lang="ts">
</script>

<style modules>
.row_list {
  display: flex; flex-direction: row; flex-wrap: wrap; justify-content: flex-start; align-content: space-around;
  gap:24px;
}

.row_list > li {
  padding:0; border:none; width:calc(50% - 12px);
}

.row_list > li:first-child {
  margin-bottom:0;
}

@media screen and (max-width: 1400px) {
  .row_list { flex-direction: column; }
  .row_list > li{ width:calc(100%); min-height:auto;}
}

</style>
