import type { UseFetchOptions } from '#app'
import type { IContentListReponse } from '../types/response'
import { useCustomFetch } from '~/modules/core/fetch/custom-fetch'

/**
 * 추천 콘텐츠 조회 - 홈
 * @param req
 * @returns
 */
export function useFetchPostListByRecommendAtHome (option?: UseFetchOptions<IContentListReponse>) {
  return useCustomFetch('/recommend/v1/contents/home', {
    ...option
  })
}
