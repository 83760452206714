import type { UseFetchOptions } from '#app'
import type { IBusinessListReponse } from '../types/response'
import { useCustomFetch } from '~/modules/core/fetch/custom-fetch'

/**
 * 인증 회사 목록 조회 (홈)
 * @param req
 * @returns
 */
export function useFetchBusinesssListByCertifyAtHome (option?: UseFetchOptions<IBusinessListReponse>) {
  return useCustomFetch('/business/v1/certify/list', {
    ...option
  })
}
