<template>
  <div class="content main">
    <div class="section">
      <div class="inner_box column_box">
        <div class="column_left">
          <tab-list-01
            v-model="selectTab"
            :list="tabList"
            :click-before="isFilterClickTab"
            @select="handleUpdateTab"
            @fail="handleFailureUpdateTab"
          />

          <interest-swiper
            v-if="isInterestPost(selectTab) && myInterestList.length > 0"
            class="mt24"
            :list="myInterestList"
            @update="handleUpdateInterest"
          />

          <template v-if="articleList.length < 1">
            <p class="text_none font_p2 mt24">
              아티클을 준비 중입니다
            </p>
          </template>
          <template v-else>
            <column-list>
              <list-item-01
                v-for="item in articleList"
                :item="item"
                :id="item.postSeq"
                :key="item.postSeq"
                :title="item.title"
                :content="item.lineSummary ?? ''"
                :thumbnail="thumbnailImage(item.thumbnailImageId ?? '')"
                :tags="convertCommaToList(item.tags, 4)"
                :writer-id="item.businessSeq"
                :writer-en-id="item.businessId"
                :writer-name="item.companyName"
                :writer-thumbnail="thumbnailImageWithOptions(item.logoImageId ?? '', {w: 38})"
                :create-date="convertDate(item.creDt)"
                :read-yn="item.readYn"
                @click-item="() => handleNavigateToPost(item)"
                @click-writer="(data: any) => navigateToBusiness(data.writerEnId)"
                @click-tag="(tag: string) => navigateToSearchContents(tag, SEARCH_AFFECT_TYPE.Related)"
              >
                <template #icon>
                  <button-bookmark
                    :id="item.postSeq"
                    :bookmark-yn="item.likeYn"
                    @update="(bookmark) => handleUpdateBookmark(bookmark, item) "
                  />
                </template>
              </list-item-01>
            </column-list>
            <a href="#" class="btn_ty_01 w100 mt24" @click.prevent="handleClickMorePostList">전체보기</a>
          </template>
        </div>

        <div class="column_right tablet_none">
          <div class="title_1">
            <h2 class="font_h6">
              주목받는 기업
            </h2>
            <tool-tip>최신 아티클 발행이 많고, 조회수가 높은 순으로 선정되었습니다</tool-tip>
          </div>
          <column-list class="ty2" style="margin-top: 16px;">
            <list-item-02
              v-for="item in businessList"
              :id="item.businessId"
              :key="item.businessSeq"
              :title="item.companyName"
              :content="item.comment"
              :thumbnail="thumbnailImageWithOptions(item.logoImageId ?? '', {w: 34})"
              @click="(data: any) => navigateToBusiness(data.id)"
            >
              <template #icon>
                <button-follow :id="item.businessSeq" class="small" :follow-yn="item.followYn" />
              </template>
            </list-item-02>
          </column-list>

          <div class="title_1 mt80">
            <h2 class="font_h6">
              <a :href="PATH.POST_REPORT_LIST" @click.prevent="navigateToReportPostList()">
                기업 보도자료
                <i class="icon_size_24 icon_font_arrow_03" />
              </a>
            </h2>
          </div>

          <column-list class="ty3" style="margin-top: 24px;">
            <list-item-03
              v-for="item in reportList"
              :item="item"
              :id="item.postSeq"
              :key="item.postSeq"
              :title="item.title"
              :writer-id="item.businessSeq"
              :writer-en-id="item.businessId"
              :writer-name="item.companyName"
              :writer-thumbnail="thumbnailImageWithOptions(item.logoImageId ?? '', {w: 38})"
              :create-date="convertDate(item.creDt)"
              :read-yn="item.readYn"
              @click-item="() => handleNavigateToPost(item)"
              @click-writer="(data: any) => navigateToBusiness(data.writerEnId)"
            />
          </column-list>
        </div>
      </div>
    </div>

    <div class="section bg">
      <div class="inner_box column_ty3">
        <div v-for="recommend in recommendList" :key="recommend.recommendSeq" class="column">
          <h2 class="title_2 font_h4">
            # {{ recommend.title }}
          </h2>
          <div class="box">
            <column-list class="ty4">
              <list-item-04
                v-for="item in recommend.contentsList.slice(0, 3)"
                :item="item"
                :id="item.postSeq"
                :key="item.postSeq"
                :title="item.title"
                :content="item.lineSummary ?? ''"
                :tags="convertCommaToList(item.tags, 4)"
                :writer-id="item.businessSeq"
                :writer-en-id="item.businessId"
                :writer-name="item.companyName"
                :writer-thumbnail="thumbnailImageWithOptions(item.logoImageId ?? '', {w: 38})"
                :create-date="convertDate(item.creDt)"
                :read-yn="item.readYn"
                @click-item="() => handleNavigateToPost(item)"
                @click-writer="(data: any) => navigateToBusiness(data.writerEnId)"
                @click-tag="(tag: string) => navigateToSearchContents(tag, SEARCH_AFFECT_TYPE.Related)"
              >
                <template #icon>
                  <button-bookmark
                    :id="item.postSeq"
                    :bookmark-yn="item.likeYn"
                    @update="(bookmark) => handleUpdateBookmark(bookmark, item)"
                  />
                </template>
              </list-item-04>
            </column-list>

            <recommend-more
              class="mt24"
              :recommend-seq="recommend.recommendSeq"
              :list="recommend.contentsList.slice(3).map((item: any) => item.logoImageId)"
              @click="navigateToRecommendPostList(recommend.recommendSeq)"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="inner_box">
        <h2 class="title_3 font_h6">
          오직 오운드에서만
        </h2>
        <row-list>
          <list-item-05
            v-for="item in onlyOundList"
            :id="item.postSeq"
            :key="item.postSeq"
            :title="item.title"
            :content="item.lineSummary ?? ''"
            :thumbnail="thumbnailImage(item.thumbnailImageId ?? '')"
            :tags="convertCommaToList(item.tags, 4)"
            :writer-id="item.businessSeq"
            :writer-en-id="item.businessId"
            :writer-name="item.companyName"
            :writer-thumbnail="thumbnailImageWithOptions(item.logoImageId ?? '', {w: 38})"
            :create-date="convertDate(item.creDt)"
            :read-yn="item.readYn"
            @click-item="() => handleNavigateToPost(item)"
            @click-writer="(data: any) => navigateToBusiness(data.writerEnId)"
          >
            <template #icon>
              <button-bookmark
                :id="item.postSeq"
                :bookmark-yn="item.likeYn"
                @update="(bookmark) => handleUpdateBookmark(bookmark, item)"
              />
            </template>
          </list-item-05>
        </row-list>
      </div>
    </div>

    <div class="section pc_none tablet_on">
      <div class="inner_box">
        <div class="title_1">
          <h2 class="font_h6">
            주목받는 기업
          </h2>
          <ToolTip>최신 아티클 발행이 많고, 조회수가 높은 순으로 선정되었습니다</ToolTip>
        </div>
        <column-list class="ty2" style="margin-top: 16px;">
          <list-item-02
            v-for="item in businessList"
            :id="item.businessSeq"
            :key="item.businessSeq"
            :title="item.companyName"
            :content="item.comment"
            :thumbnail="thumbnailImageWithOptions(item.logoImageId ?? '', {w: 34 })"
            @click="(data: any) => navigateToBusiness(data.id)"
          >
            <template #icon>
              <button-follow
                :id="item.businessSeq"
                class="small"
                :follow-yn="item.followYn"
              />
            </template>
          </list-item-02>
        </column-list>

        <div class="title_1 mt60">
          <h2 class="font_h6">
            <a :href="PATH.POST_REPORT_LIST" @click.prevent="navigateToReportPostList()">
              기업 보도자료
              <i class="icon_size_24 icon_font_arrow_03" />
            </a>
          </h2>
        </div>
        <column-list class="ty3" style="margin-top: 24px;">
          <list-item-03
            v-for="item in reportList"
            :id="item.postSeq"
            :key="item.postSeq"
            :title="item.title"
            :writer-id="item.businessSeq"
            :writer-en-id="item.businessId"
            :writer-name="item.companyName"
            :writer-thumbnail="thumbnailImageWithOptions(item.logoImageId ?? '', {w: 38})"
            :create-date="convertDate(item.creDt)"
            :read-yn="item.readYn"
            @click-item="() => handleNavigateToPost(item)"
            @click-writer="(data: any) => navigateToBusiness(data.writerEnId)"
          />
        </column-list>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SigninAuth } from "@tms/authorization";
import PATH from '../modules/content/config/content-path'

import {
  useFetchPostListByPopuplar,
  useFetchPostListByLatest,
  useFetchPostListByInterest,
  useFetchPostListByReportAtHome,
  useFetchPostListByOnlyOundAtHome
} from "~/modules/content/fetch/post-fetch";
import { useFetchPostListByRecommendAtHome } from "~/modules/content/fetch/content-fetch";
import { useFetchBusinesssListByCertifyAtHome } from "~/modules/content/fetch/business-fetch";
import { useFetchMyInterestList, useUpdatehMyInterestList } from "~/modules/content/fetch/interest-fetch";

import { SEARCH_AFFECT_TYPE, AFFECT_TYPE, TPostType } from "~/modules/content/types/type";
import type { IBusinessSummary, IContentSummary, IInterest, IPostSummary } from "~/modules/content/types/model";
import useContentNavigator from "~/modules/content/composables/navigate";
import { useMemberNavigator } from "~/modules/member/common/composables/navigator";
import { useImagesThumbnail } from "~/composables/images/thumbnail";

import { convertDate, convertCommaToList } from "~/utils/convert";
import useContent from "~/modules/content/composables/content";
import useContentTracking from "~/modules/content/composables/tracking";

const route = useRoute();
const { clearLayout } = useContent();
const {
  navigateToPopularPostList,
  navigateToLatestPostList,
  navigateToInterestPostList,
  navigateToReportPostList,
  navigateToRecommendPostList,
  navigateToSearchContents,
  navigateToPostWithAnalysis,
  navigateToBusiness
} = useContentNavigator();
const { navigateToLogin } = useMemberNavigator();
const { trackingBySelectContent, trackingByBookmarkContentList } = useContentTracking();
const { thumbnailImage, thumbnailImageWithOptions } = useImagesThumbnail();

const auth = new SigninAuth();

const confirm: any = inject("confirm");

const selectTab = ref(TPostType.POPULAR);
const tabList = ref<any[]>([
  { id: TPostType.POPULAR, title: "인기 아티클" },
  { id: TPostType.LATEST, title: "최신 아티클" },
  { id: TPostType.INTEREST, title: "맞춤 아티클" }
]);

const myInterestList = ref<IInterest[]>([]);
const articleList = ref<IPostSummary[]>([]);
const businessList = ref<IBusinessSummary[]>([]);
const reportList = ref<IPostSummary[]>([]);
const recommendList = ref<IContentSummary[]>([]);
const onlyOundList = ref<IPostSummary[]>([]);

const initData = async () => {
  const [
    fetchBusinesssListByCertifyAtHome,
    fetchPostListByReportAtHome,
    fetchPostListByRecommendAtHome,
    fetchPostListByOnlyOundAtHome
  ] = await Promise.all([
    useFetchBusinesssListByCertifyAtHome(),
    useFetchPostListByReportAtHome(),
    useFetchPostListByRecommendAtHome(),
    useFetchPostListByOnlyOundAtHome()
  ]);

  businessList.value = fetchBusinesssListByCertifyAtHome.data.value?.list ?? [];
  reportList.value = fetchPostListByReportAtHome.data.value?.list ?? [];
  recommendList.value = fetchPostListByRecommendAtHome.data.value?.list ?? [];
  onlyOundList.value = fetchPostListByOnlyOundAtHome.data.value?.list ?? [];
};

const isLatestPost = (type: string) => type === TPostType.LATEST;
const isInterestPost = (type: string) => type === TPostType.INTEREST;

/**
 * 탭 클릭 시 Action 수행 여부를 결정합니다.
 * true : 탭을 정상적으로 클릭합니다.
 * false : 탭의 클릭을 막습니다.
 */
const isFilterClickTab = async (tab: any): Promise<Boolean> => {
  return !isInterestPost(tab.id) || await auth.isSignin();
};

/**
 * 내 관심사 설정
 */
const setInterest = (interestList: IInterest[]) => {
  myInterestList.value = interestList;
  return convertInterestCodeList(interestList);
};

/**
 * 관심사 설정 업데이트
 */
const handleUpdateInterest = async (interestList: IInterest[]) => {
  const interestCodeList = interestList.flatMap((interest: IInterest) => interest.interestCd);
  const response = await useUpdatehMyInterestList({ interestList: interestCodeList });
  if (response.code !== 0) {
    return;
  }

  const interests = setInterest(interestList);
  const fetchPostListByInterest = await useFetchPostListByInterest({ currentPage: 1, cntPerPage: 5, interests });
  articleList.value = fetchPostListByInterest.data.value?.list ?? [];
};

/**
 * 탭 클릭 변경 여부
 */
const handleUpdateTab = async (tabId: string) => {
  if (isLatestPost(tabId)) {
    const fetchPostListByLatest = await useFetchPostListByLatest({ currentPage: 1, cntPerPage: 5 });
    articleList.value = fetchPostListByLatest.data.value?.list ?? [];
    setInterest([]);

    navigateTo({ path: "/", query: { tb: tabId } });
  } else if (isInterestPost(tabId)) {
    const fetchMyInterestList = await useFetchMyInterestList();
    handleUpdateInterest(fetchMyInterestList.data.value?.interestList ?? []);

  } else {
    const fetchPostListByPopuplar = await useFetchPostListByPopuplar({ currentPage: 1, cntPerPage: 5 });
    articleList.value = fetchPostListByPopuplar?.data.value?.list ?? [];
    setInterest([]);
  }

  if (route.query.tb !== tabId && !(!route.query.tb && tabId === TPostType.POPULAR)) {
    navigateTo({ path: "/", query: { tb: tabId } });
  }
};

/**
 * Tab 변경에 실패한 경우 Callback
 */
const handleFailureUpdateTab = () => {
  popupLogin();
};

/**
 * 게시글 목록 더보기 (인기 / 최근)
 */
const handleClickMorePostList = () => {
  if (isLatestPost(selectTab.value)) {
    navigateToLatestPostList();
  } else if (isInterestPost(selectTab.value)) {
    navigateToInterestPostList();
  } else {
    navigateToPopularPostList();
  }
};

const popupLogin = () => {
  confirm.open({
    title: "로그인이 필요합니다<br />로그인 페이지로 이동할까요?",
    confirm: () => {
      navigateToLogin();
    }
  });
};

/**
 * 관심사 목록 ',' 형태로 변경
 */
const convertInterestCodeList = (interestList: IInterest[]) => {
  return interestList.map((interest: IInterest) => interest.interestCd).join(",");
};

const handleNavigateToPost = (data: any) => {
  trackingBySelectContent({
    postSeq: data.postSeq,
    title: data.title,
    categoryNo: data.categoryNoDepth1,
    categoryNo2: data.categoryNoDepth2,
    companyName: data.companyName
  });
  navigateToPostWithAnalysis(data.postSeq, {
    query: {
      writer: data.companyName,
      category1: data.categoryNoDepth1,
      category2: data.categoryNoDepth2,
      affect_type: AFFECT_TYPE.HOME
    }
  });
};

const handleUpdateBookmark = (bookmarkData: any, data: any) => {
  if (bookmarkData.bookmarkYn === "Y") {
    trackingByBookmarkContentList({
      postSeq: data.postSeq,
      title: data.title,
      categoryNo: data.categoryNoDepth1,
      categoryNo2: data.categoryNoDepth2,
      companyName: data.companyName
    });
  }
};

onMounted(async () => {
  clearLayout();
  await nextTick(() => {
    selectTab.value = route.query.tb?.toString() ?? TPostType.POPULAR;
    handleUpdateTab(selectTab.value);
  });
});

await initData()
</script>

<style scoped>
.section .inner_box {
  padding: 80px 0;
}

.section .column_box {
  padding: 40px 0 80px;
  display: flex;
  justify-content: space-between;
}

.column_box .column_left {
  width: calc(100% - 510px);
}

.column_box .column_right {
  width: 384px;
}

.column_box .column_left .tab_01 + .column_list {
  margin-top: 24px;
}

.main .title_1 {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;
}

.main .title_1 .icon_font_arrow_03:before {
  color: var(--color-text-title);
}

.main .title_1 .font_h6 {
  color: var(--color-text-title);
}

.main .title_1 a {
  display: flex;
  align-items: center;
  gap: 4px;
  line-height: 1.5rem;
}

.main .title_2 {
  color: var(--color-text-title);
  padding: 0 24px 0;
  margin-bottom: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  word-break: break-all;
}

.main .title_3 {
  color: var(--color-text-title);
  padding: 0 0 24px;
}

.section.bg {
  background: var(--color-background-sub1);
}

.column_ty3 {
  display: flex;
  justify-content: flex-start;
  gap: 24px;
}

.column_ty3 .column {
  width: calc(33.33% - 16px);
}

.column_ty3 .column .box {
  background: var(--color-background-default);
  border-radius: 8px;
  padding: 8px 24px 24px;
  min-height: 521px;
  box-sizing: border-box;
}

.column_ty3 .column .column_list > li:nth-child(4) {
  display: none;
}

@media screen and (max-width: 1600px) {
  .column_box .column_left {
    width: calc(100% - 450px);
  }
}

@media screen and (max-width: 1500px) {
  .column_box .column_left {
    width: calc(100% - 430px);
  }

  .column_ty3 {
    flex-direction: column;
  }

  .column_ty3 .column {
    width: calc(100%);
    margin-bottom: 40px;
  }

  .column_ty3 .column:last-child {
    margin: 0;
  }
}

@media screen and (max-width: 1400px) {
  .column_box .column_left {
    width: calc(100%);
  }

  .tablet_on .inner_box {
    padding: 0 0 100px;
  }
}

@media screen and (max-width: 768px) {
  .section .inner_box {
    padding: 60px 0;
  }

  .section .column_box {
    padding: 20px 0 60px;
  }

  .tablet_on .inner_box {
    padding: 0 0 80px;
  }
}
</style>
