<template>
  <div class="tooltip">
    <button type="button">
      <i class=" icon_font_info" />
      <span class="txt_tooltip">
        <slot />
      </span>
    </button>
  </div>
</template>

<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
  width: 16.67px;
  ;
  height: 16.67px;
}

.tooltip .txt_tooltip {
  position: absolute;
  left: 20.34px;
  top: calc(50% - 18px);
  width: 0;
  height: 0;
  font-size: 0;
  overflow: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip:hover .txt_tooltip {
  display: block;
  width: 209px;
  height: auto;
  padding: 4px 8px;
  border-radius: 2px;
  font-size: var(--font-size-8);
  color: var(--color-background-default);
  line-height: 110%;
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
  background: var(--color-primary-10);
  border-radius: 4px;
  word-break: break-all;
  box-sizing: border-box;
  text-align: left;
  font-weight: normal;
}
</style>
