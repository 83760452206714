<template>
  <li>
    <div class="list_item" :class="{ read: readYn === 'Y' }">
      <a href="#" class="thumbnail" @click.prevent="handleClickItem">
        <span>
          <img :src="thumbnail" alt="" width="282">
        </span>
      </a>

      <div class="text_box">
        <a href="#" class="content" @click.prevent="handleClickItem">
          <span class="title font_h6">{{ title }}</span>
        </a>

        <div class="writer">
          <a href="#" @click.prevent="handleClickWriter">
            <span class="logo">
              <img :src="writerThumbnail" alt="">
            </span>
            <span class="name font_p3">{{ writerName }}</span>
            <span class="date font_p3">{{ createDate }}</span>
          </a>

          <slot />
        </div>
      </div>
    </div>
  </li>
</template>
<script setup lang="ts">
const { id, title, content, thumbnail, writerId, writerEnId, writerThumbnail, writerName, createDate, readYn } = withDefaults(
  defineProps<{
    id: string | number, title: string, content: string, thumbnail: string,
    writerId: string | number, writerEnId: string, writerThumbnail: string, writerName: string, createDate: string, readYn: string
  }>(),
  {
    id: '',
    title: '',
    content: '',
    thumbnail: '',
    writerId: '',
    writerEnId: '',
    writerThumbnail: '',
    writerName: '',
    createDate: '',
    readYn: 'N'
  }
)

const emit = defineEmits<{(e: 'clickItem', item: any): void, (e: 'clickWriter', item: any): void}>()

const handleClickItem = () => {
  emit('clickItem', {
    id, title, content, thumbnail, writerId, writerEnId, writerThumbnail, writerName, createDate
  })
}

const handleClickWriter = () => {
  emit('clickWriter', {
    id, title, content, thumbnail, writerId, writerEnId, writerThumbnail, writerName, createDate
  })
}

</script>

<style scoped>
.list_item { display: flex; justify-content: space-between; align-items: center; }
.list_item .text_box { position: relative; width:calc(100% - 204px); }
.list_item .writer { position: relative; margin-top:16px; padding-right:24px; box-sizing:border-box;}
.list_item .writer a {display: flex; align-items: center; gap: 5px; width: fit-content; }
.list_item .writer .logo { position:relative; display:inline-block; width:24px; height:24px; flex: none; background:#fff; border:1px solid var(--color-line);  border-radius:100%; box-sizing:border-box; overflow:hidden; }
.list_item .writer .logo span { display: inline-block; width: 24px; height: 24px; background: #fff; border: 1px solid var(--color-line); border-radius: 100%; box-sizing: border-box; }
.list_item .writer .logo img { position: absolute; top: -100%; right: -100%; bottom: -100%; left: -100%; width: 100%; height: 100%; margin: auto; }
.list_item .writer .name { color: var(--color-text-title); letter-spacing: 0; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden; white-space: normal; word-break: break-all; }
.list_item .writer .date { display: flex; align-items: center; gap: 5px; letter-spacing: 0; flex: none; }
.list_item .writer .date:before { content: ''; display: inline-block; width: 2px; height: 2px; background: var(--color-text-sub); border-radius: 100%; }
.list_item .writer .icon { position: absolute; right: 0; top: 0; width: 24px; }
.list_item .content .title { color: var(--color-text-headline); -webkit-line-clamp: 2; line-height: 130%; }
.list_item .content:hover .title { text-decoration: underline }
.list_item .content .txt { margin-top:4px; }
.list_item .thumbnail { position:relative; display:block; width:180px; height:102px; overflow:hidden; border-radius:8px; }
.list_item .thumbnail img { position: absolute; top: -100%; right: -100%; bottom: -100%; left: -100%; width: 100%; height: 100%; margin: auto; object-fit: cover;}

/*.list_item.read .thumbnail:after { content:''; display:block; position:absolute; left:0; top:0; width:100%; height:100%; background:var(--color-background-default); opacity:0.6; }*/
.list_item.read .content .title { color:var(--color-text-sub); }

@media screen and (max-width: 768px) {
  .list_item .text_box { width: calc(100% - 100px); padding: 0; }
  .list_item .thumbnail { width: 88px; height: 88px; }
}

</style>
